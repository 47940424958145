import { useTonConnectUI, useTonWallet } from "@tonconnect/ui-react";
import { observer } from "mobx-react-lite";
import { CSSProperties } from "react";
import { getCharacter } from "../../api/character";
import { withdraw } from "../../api/withdraw";
import { CONTRACT_ADDR, GAS_FEE, M_MS, REWARD_DATA } from "../../constants";
import { useTonClient } from "../../hooks/ton-client";
import { authStore } from "../../store/auth-store";
import { store } from "../../store/store";
import { ERewardTypes } from "../../types/types";
import { numberInGroups } from "../../utils";
import { MainBorderButtonV2, MainButtonV2 } from "../MainButton";
import { Flex, VFlex } from "../styled/Flex";
import HistorySVG from "../SVG/HistorySVG";
import MinusSVG from "../SVG/MinusSVG";
import PlusSVG from "../SVG/PlusSVG";
import { S14Body, SBody, SBody600, SBodyBold } from "../Typography";
import BaseDialogUI from "./BaseDialogUI";
interface WithdrawDialogProps {
  style?: CSSProperties;
}

const ID = "WithdrawDialog";

const WithdrawDialog = observer((props: WithdrawDialogProps) => {
  const wallet = useTonWallet();
  const [TonConnectUI] = useTonConnectUI();
  const tonClient = useTonClient();
  return (
    <BaseDialogUI
      dialogID={ID}
      storeGetter={() => {
        return store.withdrawDialogState;
        // return true;
      }}
      onShow={() => {}}
      onClose={() => {
        store.withdrawDialogState = null;
      }}
      style={{
        padding: "8px 16px",
      }}
      containerStyle={{ flex: 1, gap: 16, overflow: "hidden" }}
    >
      {(() => {
        if (!store.withdrawDialogState) return null;
        const rewardData = REWARD_DATA[store.withdrawDialogState.type];
        const balance = parseFloat(
          (store.withdrawDialogState?.type === ERewardTypes.TON
            ? authStore.character?.ton_balance
            : authStore.character?.well_balance) ?? "0",
        );
        const inputValue = parseFloat(store.withdrawDialogState.amount);
        return (
          <>
            <VFlex
              style={{
                backgroundColor: "#1c3445",
                justifyContent: "center",
                alignItems: "center",
                alignSelf: "stretch",
                borderRadius: 12,
                overflow: "hidden",
              }}
            >
              <Flex
                style={{
                  alignItems: "center",
                  height: 58,
                  gap: 6,
                }}
              >
                <img src="/img/withdraw-icon.svg" />
                <SBody style={{ fontWeight: "bold" }}>Withdraw</SBody>
              </Flex>
            </VFlex>
            <VFlex className="flex-1 overflow-y-scroll overflow-x-hidden self-stretch">
              <SBody600 className="text-center">
                Withdraw your $WELL to your wallet
              </SBody600>
              {/* <VFlex className="mt-4 min-h-[90px] rounded-[12px] items-stretch self-stretch overflow-hidden">
                <VFlex className="h-[57px] bg-[#1E3344] items-center justify-center">
                  <S14Body className="max-w-[192px] text-center">
                    Your $WELL will be sent to the following address:
                  </S14Body>
                </VFlex>
                <VFlex className="h-[48px] bg-[#0C1C27] items-center justify-center">
                  <S14Body
                    className="text-center max-w-[80%]"
                    style={{
                      whiteSpace: "stable",
                      wordBreak: "break-all",
                    }}
                  >
                    0x2af317c549193a651AFb0513F6F687150E4ca67c
                  </S14Body>
                </VFlex>
              </VFlex> */}
              <VFlex
                className="self-stretch min-h-[50px] px-4 py-2 my-4"
                style={{
                  backdropFilter: "blur(30px)",
                  border: "1px solid #82B3D6",
                  borderRadius: 9,
                  // alignItems: "center",
                }}
              >
                <S14Body className="flex-1">Your Balance:</S14Body>
                <Flex>
                  <SBody600 className="mr-2">
                    {`${numberInGroups(balance)} $${rewardData.title.toUpperCase()}`}
                  </SBody600>
                  <img className="w-6 h-6" src={rewardData.icon} />
                </Flex>
              </VFlex>
              <SBodyBold>Enter amount to withdraw</SBodyBold>
              <VFlex className="self-stretch overflow-hidden">
                <Flex className="self-stretch gap-2 overflow-hidden">
                  <VFlex
                    className="items-center justify-center w-11 h-11"
                    style={{
                      border: "2px solid #767676",
                      background:
                        "linear-gradient(0deg, #a1a1a1 0%, #d2d2d2 100%)",
                      borderRadius: 8,
                    }}
                  >
                    <MinusSVG fill="#484848" />
                  </VFlex>
                  <Flex className="flex-1 gap-2 self-stretch rounded-lg bg-black p-1 pl-4 items-center relative">
                    <VFlex style={{ flex: 1, justifyContent: "center" }}>
                      <input
                        style={{
                          fontWeight: 600,
                          fontSize: 18,
                          // width: "1px",
                          minWidth: "0px",
                          position: "absolute",
                          left: 12,
                          right: 78,
                        }}
                        placeholder="1"
                        onChange={(evt) => {
                          if (!store.withdrawDialogState) return;
                          store.withdrawDialogState.amount = evt.target.value;
                        }}
                        value={store.withdrawDialogState?.amount}
                      />
                    </VFlex>
                    <MainButtonV2
                      bgColorTop="#5481a5"
                      bgColorBottom="#3a5c78"
                      style={{ borderRadius: 6, color: "white" }}
                      onClick={() => {
                        if (!store.withdrawDialogState) return;
                        store.withdrawDialogState.amount = balance.toString();
                      }}
                    >
                      MAX
                    </MainButtonV2>
                  </Flex>
                  <VFlex
                    className="items-center justify-center w-11 h-11"
                    style={{
                      border: "2px solid #84c9ff",
                      background:
                        "linear-gradient(0deg, #3a5c79 0%, #5380a3 100%)",
                      borderRadius: 8,
                    }}
                    onClick={() => {
                      if (!store.withdrawDialogState) return;
                      const v = parseFloat(store.withdrawDialogState.amount);
                      store.withdrawDialogState.amount = Math.min(
                        v + 1,
                        balance,
                      ).toString();
                    }}
                  >
                    <PlusSVG fill="white" />
                  </VFlex>
                </Flex>
                <S14Body className="text-center">
                  {/* Minimum amount 150K $WELL */}
                </S14Body>
                <S14Body className="text-[#54DAF3] text-center mt-2">
                  Platform fee: 2%
                </S14Body>
                {(() => {
                  if (!store.withdrawDialogState?.amount) return null;
                  if (`${inputValue}` !== store.withdrawDialogState?.amount) {
                    return (
                      <S14Body className="text-[red] text-center mt-1">
                        Invalid value
                      </S14Body>
                    );
                  }
                  return (
                    <S14Body className="text-center mt-1">
                      {`You get: ${numberInGroups((inputValue ?? 0) * 0.98)} $WELL`}
                    </S14Body>
                  );
                })()}
                <MainBorderButtonV2
                  large
                  style={{
                    borderColor: "#305e84",
                    background: "#1d3445",
                    alignSelf: "stretch",
                  }}
                  className="mt-2"
                  onClick={() => {
                    store.openWithdrawalHistoryDialog();
                  }}
                >
                  <HistorySVG className="w-7 h-7" fill="white" />
                  History
                </MainBorderButtonV2>
                <Flex className="self-stretch gap-2 mt-2">
                  <MainBorderButtonV2
                    large
                    style={{
                      borderColor: "#305e84",
                      background: "#1d3445",
                      alignSelf: "stretch",
                      flex: 1,
                    }}
                    onClick={() => {
                      store.withdrawDialogState = null;
                    }}
                  >
                    Cancel
                  </MainBorderButtonV2>
                  <MainButtonV2
                    large
                    style={{
                      flex: 1,
                    }}
                    onClick={async () => {
                      if (!store.withdrawDialogState) return;
                      store.isShowLoadingDialog = true;
                      try {
                        const v = parseFloat(store.withdrawDialogState.amount);
                        if (`${v}` !== store.withdrawDialogState.amount) {
                          throw new Error("Invalid amount");
                        } else if (
                          v <
                          (store.withdrawDialogState.type === ERewardTypes.TON
                            ? authStore.minTONWithdraw
                            : authStore.minWELLWithdraw)
                        ) {
                          throw new Error(
                            "Withdraw amount should be larger than 1",
                          );
                        }
                        const result = await withdraw(
                          v,
                          store.withdrawDialogState.type,
                          wallet?.account.address ?? "",
                        );
                        const c = await getCharacter();
                        authStore.character = c;
                        await TonConnectUI.sendTransaction({
                          validUntil: Math.round(
                            (Date.now() + 5 * M_MS) / 1000,
                          ),
                          messages: [
                            {
                              address: CONTRACT_ADDR,
                              amount:
                                store.withdrawDialogState.type ===
                                ERewardTypes.TON
                                  ? GAS_FEE.withdrawTON
                                  : GAS_FEE.withdrawToken,
                              payload: result.body.payload,
                            },
                          ],
                        });
                        store.isShowWithdrawalRequestedDialog =
                          store.withdrawDialogState.type;
                      } catch (error) {
                        store.showErrorDialog(error);
                      }
                      store.isShowLoadingDialog = false;
                      // console.log("sendInternalMessage");
                      // await sendInternalMessage();
                      // console.log("sendInternalMessage resolved");
                      // const type = store.withdrawDialogState?.type;
                      // store.withdrawDialogState = null;
                      // store.isShowWithdrawalRequestedDialog = type;
                    }}
                  >
                    Withdraw
                  </MainButtonV2>
                </Flex>
              </VFlex>
            </VFlex>
          </>
        );
      })()}
    </BaseDialogUI>
  );
});

export default WithdrawDialog;
