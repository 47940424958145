import { observer } from "mobx-react-lite";
import { CSSProperties, useEffect, useRef, useState } from "react";

import {
  useTonConnectModal,
  useTonConnectUI,
  useTonWallet,
} from "@tonconnect/ui-react";
import _, { range } from "lodash";
import Lottie from "react-lottie";
import styled from "styled-components";
import { address, beginCell, TupleBuilder } from "ton-core";
import { depositTON, submitDepositTXHash } from "../../api/character";
import { playLuckyPick } from "../../api/lucky-pick";
import {
  CONTRACT_ADDR,
  ETH,
  GAS_FEE,
  M_MS,
  REWARD_DATA,
  TOKEN_ADDR,
  TON,
} from "../../constants";
import { useTonClient } from "../../hooks/ton-client";
import animationData from "../../lottie/gold-flare.json";
import { authStore } from "../../store/auth-store";
import { store } from "../../store/store";
import { ERewardTypes } from "../../types/types";
import { numberInGroups, sleep, tryForever, useSize } from "../../utils";
import LuckyPickDialogInfoPart from "../LuckyPickDialogInfoPart";
import { MainBorderButtonV2, MainButtonV2 } from "../MainButton";
import { Flex, VFlex } from "../styled/Flex";
import StylishContainer from "../StylishContainer";
import SolidCircleQuestionMarkSVG from "../SVG/SolidCircleQuestionMarkSVG";
import {
  S12Body,
  S14Body,
  S14Body600,
  S15Body600,
  S15BodyBold,
  S18Body600,
  S20BodyBold,
  S32Body600,
  S34BodyBold,
  SBody,
  SBodyBold,
} from "../Typography";
import BaseDialogUI from "./BaseDialogUI";
interface LuckyPickDialogProps {
  style?: CSSProperties;
}

const ID = "LuckyPickDialog";

const getIconByType = (type: ERewardTypes = ERewardTypes.Energy) => {
  if (type === ERewardTypes.TON) {
    return "/img/ton-coin.png";
  } else if (type === ERewardTypes.WELL) {
    return "/img/well-coin.png";
  } else if (type === ERewardTypes.Energy) {
    return "/img/orb.png";
  }
  return "";
};

const LuckyPickCard = observer(
  (props: {
    idx: number;
    value?: number;
    type?: ERewardTypes;
    hidden: boolean;
    onChoose: () => void;
  }) => {
    const { value, type, hidden, onChoose } = props;
    const [animationState, setAnimationState] = useState(0);
    useEffect(() => {
      (async () => {
        if (animationState === 0 && hidden) {
          setAnimationState(1);
          await sleep(300);
          setAnimationState(2);
        }
        if (animationState === 2 && !hidden) {
          setAnimationState(1);
          await sleep(300);
          setAnimationState(0);
        }
      })();
    }, [hidden]);
    const r = useRef();
    const size = useSize(r);
    let translateX = 0;
    let translateY = 0;
    if (size) {
      let fullWidth = size.width * 4 + 24;
      let fullHeight = size.height * 3 + 16;
      const toPointX = fullWidth / 2 - size.width / 2;
      const toPointY = fullHeight / 2 - size.height / 2;
      const row = Math.floor(props.idx / 4);
      const col = props.idx % 4;
      const x = col * size.width + Math.max(0, (col - 1) * 8);
      const y = row * size.height + Math.max(0, (row - 1) * 8);
      translateX = toPointX - x;
      translateY = toPointY - y;
    }
    return (
      <VFlex
        className="relative"
        onClick={() => {
          if (
            store.luckyPickDialogState &&
            store.luckyPickDialogState.state === "choosing"
          ) {
            onChoose && onChoose();
          }
        }}
        ref={r as any}
        style={{
          transform:
            store.luckyPickDialogState &&
            store.luckyPickDialogState.state === "choosing" &&
            animationState === 1
              ? `translate(${translateX}px, ${translateY}px)`
              : "translate(0px, 0px)",
          transition: "all 0.3s",
        }}
      >
        <img
          style={{
            width: "100%",
            position: "absolute",
            transform:
              animationState === 2 ? "rotateY(0deg)" : "rotateY(90deg)",
            transition: "all 0.3s",
          }}
          src="/img/well-card.png"
        />
        <VFlex
          style={{
            border: "1px solid #5790B8",
            borderRadius: 8,
            padding: 5,
            background: "linear-gradient(146deg, #3C6784 0%, #254053 100%)",
            width: "100%",
            height: ((size?.width || 0) * 110) / 78,
            aspectRatio: "78 / 110",
            transition: "all 0.3s",
            transform:
              animationState === 0 ? "rotateY(0deg)" : "rotateY(90deg)",
          }}
        >
          <VFlex
            style={{
              background:
                "linear-gradient(90deg, #1a2f3e 0%, #0f1b23 40%, #0f1b23 65%, #1a2f3e 100%)",
              borderRadius: 5,
              width: "100%",
              alignItems: "center",
              padding: "6px 6px 2px 6px",
            }}
          >
            <img style={{ width: 40, height: 40 }} src={getIconByType(type)} />
            <SBody
              style={{
                fontWeight: "bold",
                fontSize: type === ERewardTypes.Energy ? 14 : 16,
              }}
            >
              {type}
            </SBody>
          </VFlex>
          <SBody
            style={{
              textAlign: "center",
              fontWeight: "bold",
              marginTop: 1,
            }}
          >
            {/* {shortenNumberString(value || 0)} */}
            ??
          </SBody>
        </VFlex>
      </VFlex>
    );
  },
);

const STopLeftInfoContainer = styled(Flex)`
  /* Layout Properties */
  position: absolute;
  top: 0px;
  right: 0px;
  width: 74px;
  height: 32px;
  /* UI Properties */
  background: linear-gradient(180deg, #ffbb40 0%, #a36027 100%);
  border-radius: 0px 8px;
  opacity: 1;
  justify-content: center;
  align-items: center;
  gap: 6px;
`;

const LuckyPickDialog = observer((props: LuckyPickDialogProps) => {
  const { state, open, close } = useTonConnectModal();
  const wallet = useTonWallet();

  const [tonConnectUI, setOptions] = useTonConnectUI();

  const tonClient = useTonClient();

  const checker = async () => {
    if (!tonClient) return;
    const newTxs = await tonClient.getTransactions(
      address(wallet?.account.address ?? ""),
      {
        limit: 4,
      },
    );
    for (const tx of newTxs) {
      const hash = tx.hash().toString("hex");
      const messages = tx.outMessages.values();
      for (let i = 0; i < messages.length; i++) {
        const message = messages[i];
        try {
          const originalBody = message.body.beginParse();
          let body = originalBody.clone();
          const uint = body.loadUint(32);
          if (uint === 0x0f8a7ea5 || uint === 4) {
            const res = await submitDepositTXHash(hash);
            authStore.character = res.body.character;
          }
        } catch (error) {}
      }
    }
  };
  return (
    <BaseDialogUI
      dialogID={ID}
      storeGetter={() => {
        return store.luckyPickDialogState;
        // return true;
      }}
      onShow={() => {}}
      onClose={() => {
        store.luckyPickDialogState = false;
      }}
      style={{
        padding: "8px 16px",
        gap: 8,
      }}
      dialogContainerStyle={{ height: "80vh", overflow: "scroll" }}
      // footer={
      //   <Flex className="justify-center text-[#5EDAFF]">
      //     📣 Someone earned 0.1 TON! 📣
      //   </Flex>
      // }
    >
      {store.luckyPickDialogState !== false && (
        <>
          <Flex
            style={{
              gap: 16,
              height: 58,
              backgroundColor: "#0C1C2770",
              justifyContent: "center",
              alignItems: "center",
              alignSelf: "stretch",
              borderRadius: 12,
            }}
          >
            <img src="/img/lucky-pick-icon.svg" />
            <SBody style={{ fontWeight: "bold" }}>Lucky Pick</SBody>
          </Flex>
          {(() => {
            if (typeof store.luckyPickDialogState?.state === "object") {
              return null;
            }
            const banner = (
              <VFlex
                className="rounded-lg overflow-hidden relative simple-btn"
                onClick={() => {
                  if (typeof store.luckyPickDialogState === "object") {
                    store.luckyPickDialogState.isShowInfo = "jackpot";
                  }
                }}
              >
                <img src="/img/lucky-jackpot-v2.png" />
                <Flex className="bg-[#1B3243] h-[30px] justify-center items-center">
                  {/* <S12BodyBold>
                    Prize pool:&nbsp;USD&nbsp;
                    <span className="text-[#FFCA77]">$500,000</span>&nbsp;in TON
                    & WELL
                  </S12BodyBold> */}
                  <S15Body600>$WELL Jackpot:&nbsp;</S15Body600>
                  <S15Body600 className="text-[#FFBB40]">
                    {numberInGroups(
                      store.luckyPickPools?.well_pool.balance ?? 0,
                    )}
                  </S15Body600>
                </Flex>
                <Flex className="bg-[#1B3243] h-[30px] justify-center items-center">
                  <S15Body600>$TON Jackpot:&nbsp;</S15Body600>
                  <S15Body600 className="text-[#FFBB40]">
                    {numberInGroups(
                      store.luckyPickPools?.ton_pool.balance ?? 0,
                    )}
                  </S15Body600>
                </Flex>
                <STopLeftInfoContainer>
                  <S14Body600 style={{ color: "black" }}>Info</S14Body600>
                  <SolidCircleQuestionMarkSVG fill="black" />
                </STopLeftInfoContainer>
              </VFlex>
            );
            if (typeof store.luckyPickDialogState?.isShowInfo === "string") {
              return <LuckyPickDialogInfoPart banner={banner} />;
            }
            if (store.luckyPickDialogState?.state === "initial") {
              const onClick = async (k: ERewardTypes) => {
                if (!authStore.character) return;
                if (!wallet?.account) {
                  const ans = await store.confirm({
                    title: "Connect wallet",
                    content: `Please connect wallet to continue`,
                  });
                  if (!ans) return;
                  open();
                  return;
                }

                try {
                  const walletAddress = address(wallet.account.address);

                  if (k === ERewardTypes.TON) {
                    store.isShowLoadingDialog = true;
                    const cost = BigInt(
                      Math.round(
                        authStore.character.lucky_pick_ton_cost * Number(TON),
                      ),
                    );
                    const total = cost;

                    const res = await depositTON(
                      authStore.character.lucky_pick_ton_cost,
                      wallet.account.address,
                    );

                    await tonConnectUI.sendTransaction({
                      validUntil: Math.round((Date.now() + 5 * M_MS) / 1000),
                      messages: [
                        {
                          address: CONTRACT_ADDR,
                          amount: total.toString(),
                          payload: res.body.payload,
                        },
                      ],
                    });

                    store.isShowLoadingDialog = false;
                    store.isShowTxInProgressDialog = true;

                    await tryForever(async () => {
                      if (store.confirmDialogState) return;
                      await checker();
                      if (
                        parseFloat(authStore.character?.ton_balance ?? "0") >=
                        (authStore.character?.lucky_pick_ton_cost ?? 0)
                      ) {
                        store.isShowTxInProgressDialog = false;
                        store.isShowLoadingDialog = false;
                        await store.confirm({
                          title: "Success",
                          content:
                            "Your transaction has been submitted successfully",
                        });
                        return;
                      }
                      throw new Error("not updated");
                    }, "deposit");
                  } else if (k === ERewardTypes.WELL) {
                    store.isShowLoadingDialog = true;
                    const jettonMinter = address(TOKEN_ADDR);
                    const builder = new TupleBuilder();
                    builder.writeAddress(walletAddress);

                    const jetton = await tonClient?.runMethod(
                      jettonMinter,
                      "get_wallet_address",
                      builder.build(),
                    );

                    const jettonWalletAddress =
                      jetton?.stack.readAddress().toString() ?? "";
                    const cost = Math.ceil(
                      authStore.character.lucky_pick_well_cost,
                    );
                    const queryId =
                      BigInt(Math.floor(Date.now() / 1000)) * BigInt(2 ** 32) +
                      BigInt(Math.floor(Math.random() * 2 ** 32));

                    const payload = beginCell()
                      .storeUint(0x0f8a7ea5, 32)
                      .storeUint(queryId, 64)
                      .storeCoins(Math.round(cost * Number(ETH)))
                      .storeAddress(address(CONTRACT_ADDR))
                      .storeAddress(walletAddress)
                      .storeUint(0, 1)
                      .storeCoins(0)
                      .storeUint(0, 1)
                      .endCell()
                      .toBoc()
                      .toString("base64");

                    await tonConnectUI.sendTransaction({
                      validUntil: Math.round((Date.now() + 5 * M_MS) / 1000),
                      messages: [
                        {
                          address: jettonWalletAddress,
                          amount: GAS_FEE.depositToken.toString(),
                          payload,
                        },
                      ],
                    });

                    store.isShowLoadingDialog = false;
                    store.isShowTxInProgressDialog = true;

                    await tryForever(async () => {
                      if (store.confirmDialogState) return;
                      await checker();
                      if (
                        parseFloat(authStore.character?.well_balance ?? "0") >=
                        (authStore.character?.lucky_pick_well_cost ?? 0)
                      ) {
                        store.isShowTxInProgressDialog = false;
                        store.isShowLoadingDialog = false;
                        await store.confirm({
                          title: "Success",
                          content:
                            "Your transaction has been submitted successfully",
                        });
                        return;
                      }
                      store.isShowLoadingDialog = false;
                      store.isShowTxInProgressDialog = false;
                      await store.confirm({
                        title: "Success",
                        content:
                          "Your transaction has been submitted successfully",
                      });
                      return;
                    }, "deposit");
                  }

                  if (store.luckyPickDialogState !== false) {
                    store.luckyPickDialogState.state = "choosing";
                    store.luckyPickDialogState.poolType = k as ERewardTypes;
                  }
                } catch (error) {
                  store.showErrorDialog(error);
                  store.isShowTxInProgressDialog = false;
                  store.isShowLoadingDialog = false;
                }
              };
              return (
                <>
                  {banner}
                  <Flex className="self-stretch gap-2">
                    <img className="w-[40%]" src="/img/lucky-pick-info.png" />
                    <VFlex className="flex-1">
                      <S15BodyBold>Pick a card and win!</S15BodyBold>
                      <S14Body>
                        Each card hides a prize! When the game starts, the 12
                        cards will be shuffled. Pick a card to win—it's that
                        easy!
                      </S14Body>
                    </VFlex>
                  </Flex>
                  <Flex className="self-stretch gap-2">
                    <MainBorderButtonV2
                      className="flex-1"
                      onClick={() => {
                        store.luckyPickDialogState &&
                          (store.luckyPickDialogState.isShowInfo = "how");
                      }}
                    >
                      How to play?
                    </MainBorderButtonV2>
                    <MainBorderButtonV2
                      className="flex-1"
                      onClick={() => {
                        store.luckyPickDialogState &&
                          (store.luckyPickDialogState.isShowInfo = "rewards");
                      }}
                    >
                      Rewards
                    </MainBorderButtonV2>
                  </Flex>
                  {/* Pick buttons */}
                  <MainButtonV2
                    style={{
                      alignSelf: "stretch",
                    }}
                    disabled={
                      (authStore.character?.lucky_pick_energy_cost ?? 0) >
                      (authStore.character?.energy ?? 0)
                    }
                    onClick={() => onClick(ERewardTypes.Energy)}
                  >
                    Play
                    <img
                      style={{ width: 30, height: 30 }}
                      src={REWARD_DATA.energy.icon}
                    />
                    <VFlex
                      style={{
                        alignItems: "flex-start",
                      }}
                    >
                      <S14Body style={{ fontWeight: "bold" }}>
                        {authStore.character?.lucky_pick_energy_cost}
                      </S14Body>
                      <S12Body style={{ marginTop: -10, fontWeight: 600 }}>
                        {REWARD_DATA.energy.title}
                      </S12Body>
                    </VFlex>
                  </MainButtonV2>
                  {/* {authStore.isTestAcc && ( */}
                  <Flex className="gap-2">
                    {[ERewardTypes.WELL, ERewardTypes.TON].map((k) => {
                      const data = REWARD_DATA[k];
                      return (
                        <VFlex
                          style={{
                            flex: 1,
                            position: "relative",
                            alignItems: "center",
                          }}
                          onClick={() => onClick(k)}
                        >
                          <img
                            style={{ width: "100%", height: "auto" }}
                            src="/img/golden-frame-h.svg"
                          />
                          <VFlex
                            style={{
                              top: 14,
                              width: "90%",
                              height: "100%",
                              position: "absolute",
                            }}
                          >
                            <img
                              style={{
                                height: "auto",
                                width: "100%",
                                objectFit: "contain",
                              }}
                              src="/img/pot-and-txt.png"
                            />
                            <Flex
                              style={{ color: "black" }}
                              className="items-center gap-1 justify-between px-2 mt-[-4px]"
                            >
                              <SBodyBold>Play</SBodyBold>
                              <img className="w-7 h-7" src={data.icon} />
                              <VFlex>
                                <SBodyBold>
                                  {data === REWARD_DATA.ton
                                    ? authStore.character?.lucky_pick_ton_cost
                                    : authStore.character?.lucky_pick_well_cost}
                                </SBodyBold>
                                <S14Body600 className="mt-[-4px]">
                                  ${data.title}
                                </S14Body600>
                              </VFlex>
                            </Flex>
                          </VFlex>
                        </VFlex>
                      );
                    })}
                  </Flex>
                  {/* )} */}
                </>
              );
            }
            return (
              <>
                <S14Body style={{ textAlign: "center" }}>
                  Play Lucky Pick to win rewards in $TON, $WELL or Energy
                  points.
                </S14Body>
                <div
                  style={{
                    transition: "all 0.3s",
                  }}
                  className={"grid grid-cols-4 gap-x-2 gap-y-2 w-full"}
                >
                  {range(0, 12)
                    .map((n) => ({
                      type: ERewardTypes.TON,
                      value: 0.01,
                    }))
                    .map(({ type: t, value }, idx) => {
                      const type = t as
                        | ERewardTypes.TON
                        | ERewardTypes.WELL
                        | ERewardTypes.Energy;
                      return (
                        <LuckyPickCard
                          type={type}
                          idx={idx}
                          value={value}
                          key={`${type}-${value}`}
                          hidden={
                            store.luckyPickDialogState &&
                            store.luckyPickDialogState.state !== "initial" &&
                            store.luckyPickDialogState.state !== idx
                          }
                          onChoose={async () => {
                            if (
                              typeof store.luckyPickDialogState === "object" &&
                              store.luckyPickDialogState.poolType
                            ) {
                              try {
                                store.isShowLoadingDialog = true;
                                const result = await playLuckyPick(
                                  store.luckyPickDialogState.poolType,
                                );
                                authStore.character = result.body.character;
                                store.isShowLoadingDialog = false;
                                store.luckyPickDialogState.state = idx;
                                store.luckyPickDialogState.state = {
                                  type: result.body.reward.currency,
                                  value: result.body.reward.amount,
                                  isJackpot: result.body.reward.is_jackpot,
                                };
                              } catch (error) {
                                store.showErrorDialog(error);
                                store.isShowLoadingDialog = false;
                              }
                            }
                          }}
                        />
                      );
                    })}
                </div>
              </>
            );
          })()}
          {(() => {
            if (!store.luckyPickDialogState) return null;
            if (typeof store.luckyPickDialogState.state === "object") {
              const valueStr = numberInGroups(
                store.luckyPickDialogState.state.value,
                3,
              );
              const rewardData =
                REWARD_DATA[store.luckyPickDialogState.state.type] ||
                REWARD_DATA.energy;
              return (
                <VFlex
                  style={{
                    alignSelf: "stretch",
                    alignItems: "center",
                    flex: 1,
                    justifyContent: "center",
                    gap: 14,
                  }}
                >
                  <VFlex
                    style={{
                      alignItems: "center",
                      flex: 1,
                      alignSelf: "stretch",
                    }}
                  >
                    <S18Body600>Congratulations!</S18Body600>
                    <S18Body600>Here is your prize:</S18Body600>
                    {store.luckyPickDialogState.state.isJackpot ? (
                      <>
                        <VFlex className="flex-1 relative justify-center items-center">
                          <img
                            style={{
                              position: "absolute",
                              maxWidth: "30%",
                              zIndex: 5,
                            }}
                            src="/img/lucky-jackpot-icon.png"
                          />
                          <Lottie
                            style={{
                              position: "absolute",
                              pointerEvents: "none",
                              zIndex: 2,
                            }}
                            height={store.screenSize.width * 0.8}
                            width={store.screenSize.width * 0.8}
                            options={{
                              loop: true,
                              autoplay: true,
                              animationData: animationData,
                              rendererSettings: {
                                preserveAspectRatio: "xMidYMid slice",
                              },
                            }}
                          />
                          <img
                            style={{
                              maxWidth: "40%",
                              zIndex: 1,
                            }}
                            src="/img/golden-frame.png"
                          />
                        </VFlex>
                        <Flex className="items-center gap-2 mt-[-20px] mb-5">
                          <img
                            src={rewardData.icon}
                            style={{ height: 32, width: 32 }}
                          />
                          <S32Body600>
                            {numberInGroups(
                              store.luckyPickDialogState.state.value,
                              3,
                            ) +
                              " $" +
                              rewardData.title.toUpperCase()}
                          </S32Body600>
                        </Flex>
                      </>
                    ) : (
                      <>
                        <StylishContainer
                          style={{
                            width: 195,
                            height: 274,
                            marginTop: 25,
                          }}
                        >
                          <img
                            style={{ width: 92, height: 92, marginTop: 45 }}
                            src={getIconByType(
                              store.luckyPickDialogState.state.type,
                            )}
                          />
                          <S34BodyBold
                            style={{
                              marginTop: 16,
                              fontSize: (() => {
                                if (valueStr.length > 8) {
                                  return 24;
                                }
                                return 34;
                              })(),
                            }}
                          >
                            {valueStr}
                          </S34BodyBold>
                          <S20BodyBold
                            style={{
                              marginTop: 0,
                            }}
                          >
                            {_.upperFirst(
                              store.luckyPickDialogState.state.type,
                            )}
                          </S20BodyBold>
                        </StylishContainer>
                        <div style={{ flex: 1, minHeight: 18 }} />
                      </>
                    )}
                    <MainButtonV2
                      style={{
                        height: 54,
                        alignSelf: "stretch",
                      }}
                      onClick={() => {
                        store.openLuckyPickDialog();
                      }}
                    >
                      Done
                    </MainButtonV2>
                  </VFlex>
                </VFlex>
              );
            }
            return (
              store.luckyPickDialogState.state !== "initial" && (
                <VFlex
                  style={{
                    alignSelf: "stretch",
                    alignItems: "center",
                    flex: 1,
                    justifyContent: "center",
                    gap: 14,
                  }}
                >
                  <SBody style={{ fontWeight: "bold", textAlign: "center" }}>
                    Choose by tapping the card you want to reveal
                  </SBody>
                </VFlex>
              )
            );
          })()}
        </>
      )}
    </BaseDialogUI>
  );
});

export default LuckyPickDialog;
